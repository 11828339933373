import React, { useState } from 'react'
import { Link } from 'gatsby'

const Footer = () => {
    /* const [ email, setEmail ] = useState()
    const [ subscribed, setSubscribed ] = useState(false)
    const subscribeEmail = (e) => {
        setEmail(e)
        console.log('email',e)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('eeee',email)
        const options = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({email: email})
        }
        fetch('https://ofiasmini-license-api-fa.azurewebsites.net/api/subscriptionEmail?code=zwptdPt72lfz73e0LTtbacyrWL1moxBYipjAIhOB6J4OzYcnavv3IA==',
            options)
            .then (res => res.json())
            .then(data => {
                if(data.msg)
                    setSubscribed(true)
            })
            .catch(err => console.log('eerr',err))
    } */
    return (
        <div>
            {/* <div className="flex flex-wrap justify-center bg-mainColor text-white p-6">
                <div className="grid grid-cols-1 md:grid-cols-3 mb-4 w-full">
                    <div className="w-full  text-center ">
                        <h4 className="text-xl py-4">About Us</h4>
                        <ul>
                            <li><Link className="text-white" to={'/company'}>Company</Link></li>
                            <li><Link className="text-white" to={'/company'}>Philosophy and Mission</Link></li>
                            <li><Link className="text-white" to={'/company'}>Meet the founders </Link></li>
                        </ul>
                    </div>
                    <div className="w-full text-white text-center">
                        <h4 className="text-xl py-4">Connect</h4>
                        <ul>

                            <li><Link className="text-white" to={'/blog'}>Blog</Link></li>
                            <li><Link className="text-white" to= {'/contact'}>Contact us</Link></li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <h4 className="text-xl py-4">Subscribe</h4>
                        <form onSubmit={(e)=>handleSubmit(e)}>
                            <div className="mb-4">
                                <input onChange={(e)=>subscribeEmail(e.target.value)} className="bg-gray-100 w-2/3  appearance-none border-4 border-yellowColor rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-100" id="inline-full-name" type="email" required placeholder="Email"/>
                            </div>
                            <button  className="bg-yellowColor font-bold  text-mainColor transition-shadow py-2 px-4 rounded" type="submit">Join Community</button>
                            {subscribed ? <p className="mt-2">Thank you for subscribing with us!</p> : null}
                        </form>
                    </div>
                </div>
            </div> 
            <hr className="m-0"/> */}
            <div className="bg-white text-white pl-6 p-2 text-center">
                <div className="bottom text-mainColor">Copyright &copy; Ricta Technologies SRL 2021. All rights reserved</div>
            </div>
        </div>
    )

}
export default Footer