import React from 'react'
import { Link } from 'gatsby'
import Logo from '../assets/rictaLogoBlue.svg'

const Header = () => {

    return (
        <div className="sticky top-0 z-99999">
            <nav
                className="flex items-center z-50 justify-center flex-wrap py-2 lg:px-5 shadow bg-white">
                <div className="flex justify-center lg:w-auto w-full border-solid border-gray-300 pb-0">
                    <div className="flex items-center justify-center">
                        <Link to='/' className="text-white pointer"><Logo className="logo" alt="avatar"/></Link>
                    </div>
                    {/*<div className="block lg:hidden pr-3 ">
                        <button
                            onClick={()=>handleClick()}
                            id="nav"
                            className="flex items-center z-50 px-3 p-2 border-2 border-textColor rounded text-textColor">
                            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                            </svg>
                        </button>
    </div> */}
                </div>

            </nav>
        </div>
    )

}
export default Header